/**
 * @type {object}
 * @property {object} isError error messages 
 * @returns true if the form fields are all valid
 */
export function formIsAllValid({ isError, ...rest }) {
    let isValid = false;

    Object.values(isError).forEach(val => {
        if (val.length > 0) {
            isValid = false
        } else {
            isValid = true
        }
    });

    Object.values(rest).forEach(val => {
        if (val.length <= 0) {
            isValid = false
        } else {
            isValid = true
        }
    });

    return isValid;
};

/**
 * @type {object}
 * @property {object} isError error messages 
 * @returns true if the form is valid
 */
export function formIsValid(isError) {
    let isValid = true;
    Object.values(isError).forEach((val) =>
        // if we have an error string set valid to false
        val.length > 0 && (isValid = false)
    );

    return isValid;
};


/**
 * Function that triggers the form value change.
 *
 * @param {*} event 
 * @param {*} data the data of page
 * @param {*} setData Callback that updates the stateful value
 * @param {string} errorMessage the error message for field
 */
export function formValChangeWithParents(event, data, setData, errorMessage) {
    event.preventDefault();
    // get input tag name and value
    let { name, value } = event.target;
    let isError = { ...data.isError };
    const objectName = name.split(".")[0];
    const fieldNested = name.split(".")[2] !== undefined ? name.split(".")[2] : undefined;
    name = name.split(".")[1];
    const fieldNested2 = name.split(".")[3] !== undefined ? name.split(".")[3] : undefined;
    const fieldNested3 = name.split(".")[4] !== undefined ? name.split(".")[4] : undefined;
    const fieldNested4 = name.split(".")[5] !== undefined ? name.split(".")[5] : undefined;


    if (name in isError) {
        isError[name] = value.length < 1 ? errorMessage : "";
    }

    if (fieldNested4 !== undefined)
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: {
                        ...data[objectName][name][fieldNested],
                        [fieldNested2]: {
                            ...data[objectName][name][fieldNested][fieldNested2],
                            [fieldNested3]: {
                                ...data[objectName][name][fieldNested][fieldNested2][fieldNested3],
                                [fieldNested4]: value
                            }
                        }
                    }
                }
            },
            isError
        });
    else if (fieldNested3 !== undefined)
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: {
                        ...data[objectName][name][fieldNested],
                        [fieldNested2]: {
                            ...data[objectName][name][fieldNested][fieldNested2],
                            [fieldNested3]: value
                        }
                    }
                }
            },
            isError
        });
    else if (fieldNested2 !== undefined)
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: {
                        ...data[objectName][name][fieldNested],
                        [fieldNested2]: value
                    }
                }
            },
            isError
        });
    else if (fieldNested !== undefined)
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: value
                }
            },
            isError
        });
    else
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: value
            },
            isError
        });
};


/**
 * Function that triggers the form value change.
 *
 * @param {type} event 
 * 
 * @param {*} event 
 * @param {*} data the data of page
 * @param {*} setData Callback that updates the stateful value
 */
export function formValChangeWithParentElement(event, data, setData, errorMessage) {
    event.preventDefault();
    // get input tag name and value
    let { name, value } = event.target;
    let isError = { ...data.isError };
    const objectName = name.split(".")[0];
    const fieldNested = name.split(".")[2] !== undefined ? name.split(".")[2] : "";
    name = name.split(".")[1];

    // for menu-add-edit page
    if (name === "recurrenceDaysNumber" && value < 1) {
        value = 1;
    }

    if (name in isError) {
        isError[name] = value.length < 1 ? errorMessage : "";
    }

    if (fieldNested === "") {
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: value
            },
            isError,
        })
    } else {
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: value
                }
            },
            isError,
        })
    }
};

/**
 * Function that triggers the form value change.
 * 
 * @param {string} tagName the tag name
 * @param {string} tagValue the tag value
 * @param {*} data the data of page
 * @param {*} setData Callback that updates the stateful value
 */
export function formValChangeWithParentElementWithNameAndValue(tagName, value, data, setData) {
    const objectName = tagName.split(".")[0];
    const fieldNested = tagName.split(".")[2] !== undefined ? tagName.split(".")[2] : "";
    const fieldNested2 = tagName.split(".")[3] !== undefined ? tagName.split(".")[3] : "";
    const name = tagName.split(".")[1] !== undefined ? tagName.split(".")[1] : "";

    if (name === "")
        setData({
            ...data,
            [objectName]: value
        })
    else if (fieldNested2 !== "")
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: {
                        ...data[objectName][name][fieldNested],
                        [fieldNested2]: value
                    }
                }
            }
        });
    else if (fieldNested === "") {
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: value
            }
        })
    } else {
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: value
                }
            }
        })
    }
};

/**
 * Function that triggers the multi select input value change.
 * 
 * @param {*} event 
 * @param {*} data the data of page
 * @param {*} setData Callback that updates the stateful value
 */
export function onSelectChange(event, data, setData) {
    const objectName = event.target.name.split(".")[0];
    let name = event.target.name.split(".")[1];

    if (name !== undefined)
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: event.target.value
            }
        });
    else
        setData({
            ...data,
            [objectName]: event.target.value
        });
}

/**
 * Function that triggers the select input value change.
 *
 * @param {*} event 
 * @param {*} data the data of page
 * @param {*} setData Callback that updates the stateful value
 */
export function onSelectChangeWithParents(event, data, setData) {
    let { name, value } = event.target;
    const objectName = name.split(".")[0];
    const fieldNested = name.split(".")[2] !== undefined ? name.split(".")[2] : undefined;
    const fieldNested2 = name.split(".")[3] !== undefined ? name.split(".")[3] : undefined;
    const fieldNested3 = name.split(".")[4] !== undefined ? name.split(".")[4] : undefined;
    const fieldNested4 = name.split(".")[5] !== undefined ? name.split(".")[5] : undefined;
    name = name.split(".")[1];

    if (fieldNested4 !== undefined)
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: {
                        ...data[objectName][name][fieldNested],
                        [fieldNested2]: {
                            ...data[objectName][name][fieldNested][fieldNested2],
                            [fieldNested3]: {
                                ...data[objectName][name][fieldNested][fieldNested2][fieldNested3],
                                [fieldNested4]: value
                            }
                        }
                    }
                }
            }
        });
    else if (fieldNested3 !== undefined)
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: {
                        ...data[objectName][name][fieldNested],
                        [fieldNested2]: {
                            ...data[objectName][name][fieldNested][fieldNested2],
                            [fieldNested3]: value
                        }
                    }
                }
            }
        });
    else if (fieldNested2 !== undefined)
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: {
                        ...data[objectName][name][fieldNested],
                        [fieldNested2]: value
                    }
                }
            }
        });
    else
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: {
                    ...data[objectName][name],
                    [fieldNested]: value
                }
            }
        });
}

export default class formValidation {
    static formIsAllValid({ isError, ...rest }) { formIsAllValid({ isError, ...rest }); }
    static formIsValid({ isError, ...rest }) { formIsValid({ isError, ...rest }); }
    static formValChangeWithParents(event, data, setData, errorMessage) { formValChangeWithParents(event, data, setData, errorMessage); }
    static formValChangeWithParentElement(event, data, setData, errorMessage) { formValChangeWithParentElement(event, data, setData, errorMessage); }
    static onSelectChange(event, data, setData) { onSelectChange(event, data, setData); }
}