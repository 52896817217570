import { Reviews, Wifi } from "@mui/icons-material";
import { Grid, SvgIcon } from "@mui/material";
import outletApi from 'api/outlet';
import { FontAwesomeIcon, faMoneyBill } from "assets/fontAwesome/FontAwesomeIcons";
import { ShoppingCartIcon, WavingHandIcon } from "assets/mui/MuiIcons";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomOutletDetailsCard from "components/Card/outlet/CustomOutletDetailsCard";
import CustomDialogBasedOnScreenChildren from "components/Dialog/CustomDialogBasedOnScreenChildren";
import CustomDialogChildren from "components/Dialog/CustomDialogChildren";
import { getDialogTypeAnimation } from "components/Dialog/animation/dialogTypeAnimation";
import CustomGrid from 'components/Grid/CustomGrid';
import Splashscreen from "components/Grid/Splash/SplashScreen";
import CustomTopHeaderChildren from "components/Header/CustomTopHeaderChildren";
import OutletActionsLoadingSkeleton from "components/Loading/OutletActionsLoadingSkeleton";
import CustomOutletSection from "components/Section/CustomOutletSection";
import { getOutletActionButton, getOutletActionButtonSvg } from "constants/fieldProperties";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import { updateScanDetails } from "store/scan/scanActions";
import { updateOutletSplashConfiguration } from "store/splash/splashAction";
import { mainTag, outletActionButtonFontAwesomeIcon, outletActionButtonIcon, outletActionButtonIconSvg } from 'themes/defaultThemes';
import { getLanguageFromURL } from 'utils/language';
import useDocumentTitle from "utils/useDocumentTitle";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { Waitlist } from "assets/svg/SvgIcons";

/**
 * The OutletComponent, that presents a test component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OutletComponent() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation()
    const { outletURL } = useParams();

    const params = new URLSearchParams(location.search);
    const equipmentLabel = params.get("e");
    const ss = params.get("ss");

    const dispatch = useDispatch();
    dispatch(updateScanDetails(outletURL, equipmentLabel));

    //change document title
    useDocumentTitle(outletURL);

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        language: getLanguageFromURL(),
        organization: null,
        outlet: null,
        settings: null,
        extraOutletActionButtons: null,
        actions: []
    });

    const [isLoaded, setIsLoaded] = useState(false);
    const [isOpenLogin, setIsOpenLogin] = useState(false);

    const [dialog, setDialog] = useState({
        isOpen: false,
        type: "success",
        message: ""
    });

    const [outletDialog, setOutletDialog] = useState({
        isOpen: false,
        type: "success",
        message: ""
    });

    // splash
    let outletSplashConfiguration = useSelector(state => state.splash[outletURL] || null);
    const [splashCount, setSplashCount] = useState(0);
    const [showSplash, setShowSplash] = useState(true);

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} type The type of the dialog 'success', 'fail' or 'info'.
     */
    function handleOutletDialogState(isOpen, type = "success", message = "") {
        setOutletDialog({
            ...outletDialog,
            isOpen: isOpen,
            type: type,
            message: message
        });
    }

    useEffect(() => {
        if (ss !== "1") setShowSplash(false);
        getOutletActions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && ss === "1") {
            const interval = setInterval(() => {
                setSplashCount((prevCount) => prevCount + 1);
            }, 1000); // Increment count every second

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ss]);


    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && splashCount >= outletSplashConfiguration?.splashConfiguration.durationInSeconds && showSplash) {
            setShowSplash(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [splashCount]);


    function getOutletActions() {
        let userAuthenticated = localStorage.getItem("token");
        outletApi.fetchActions(outletURL, equipmentLabel, userAuthenticated !== null, ss === 1).then((r) => {
            if ((r.data.code === "SUCCESS" || r.data?.code === "PERMISSION")) {
                Promise.resolve()
                    .then(() => {
                        if (r.data.returnobject.actions?.length === 1 && r.data.returnobject.actions.includes("MENU")) {
                            navigate(`/${getLanguageFromURL()}/menu/${outletURL}?e=${equipmentLabel}`);
                        }
                        if (r.data?.code === "PERMISSION") {
                            setIsOpenLogin(true);
                        } else {
                            setIsOpenLogin(false);
                        }
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            organization: r.data.returnobject.organization,
                            outlet: r.data.returnobject.outlet,
                            actions: r.data.returnobject.actions,
                            settings: r.data.returnobject.settings,
                            extraOutletActionButtons: r.data.returnobject.extraOutletActionButtons
                        });
                    })
                    .then(() => {
                        dispatch(updateOutletSplashConfiguration(
                            outletURL,
                            r.data.returnobject.splashScreenConfiguration,
                            r.data.returnobject.organization.id,
                            r.data.returnobject.outlet.id,
                            r.data.returnobject.outlet.logo,
                            "menu"
                        ));
                    })
                    .then(() => {
                        if (ss) {
                            // Remove "ss" parameter from URL
                            params.delete("ss");
                            const newUrl = `${window.location.pathname}?${params.toString()}`;
                            window.history.replaceState({}, '', newUrl);
                        }
                    })
            }

            if (r.data.code === "INVALID") {
                handleDialogState(true, "info", r.data.message)
            }
            setIsLoaded(true);
        }).catch((e) => {
            handleDialogState(true, "fail", e.message)
            setIsLoaded(true);
            // console.log(e);
        });
    }

    // ===============
    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} type The type of the dialog 'success', 'fail' or 'info'.
     */
    function handleDialogState(isOpen, type = "success", message = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            type: type,
            message: message
        });
    }

    // ===============================================================================RENDER

    if (showSplash) {
        return <Splashscreen outletSplashConfiguration={outletSplashConfiguration}></Splashscreen>
    } else {
        if (!isLoaded) {
            return <CustomGrid role="main" id="outlet" sx={mainTag()}>
                <CustomTopHeaderChildren
                    key="th"
                    isOpen={isOpenLogin}
                    handleLoginCheckout={() => getOutletActions()}
                    setIsOpen={setIsOpenLogin}
                    pageRequireLoginRegistation={isOpenLogin}
                />
                <OutletActionsLoadingSkeleton lines={9} />
            </CustomGrid>
        } else
            return (
                <CustomGrid role="main" id="outlet" sx={mainTag()}>
                    <CustomTopHeaderChildren
                        key="th"
                        isOpen={isOpenLogin}
                        handleLoginCheckout={() => getOutletActions()}
                        setIsOpen={setIsOpenLogin}
                        pageRequireLoginRegistation={isOpenLogin}
                    />

                    <Grid item xs={12} sx={{ padding: "0px!important" }}>
                        <CustomOutletSection
                            outlet={data.outlet}
                            handleOpen={handleOutletDialogState}
                            organizationID={data.organization?.id}
                            outletConfiguration={data.outlet?.settings}
                            outletAverageRating={data.outlet?.outletAverageRating}
                            outletCountRating={data.outlet?.outletCountRating}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent={"center"} justifyItems={"center"} sx={{ width: "92%", margin: "10px" }}>
                            {data.actions?.includes("PAYMENT") &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getOutletActionButton("pay", t('outlet.type.PAYMENT'))}
                                        icon={<FontAwesomeIcon icon={faMoneyBill} style={outletActionButtonFontAwesomeIcon()} />}
                                        link={`/${getLanguageFromURL()}/pay/${outletURL}?e=${equipmentLabel}`}
                                    />
                                </Grid>
                            }

                            {data.actions?.includes("MENU") &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getOutletActionButton("order", t('outlet.type.MENU'))}
                                        icon={<ShoppingCartIcon {...getOutletActionButton()} sx={outletActionButtonIcon()} />}
                                        link={`/${getLanguageFromURL()}/menu/${outletURL}?e=${equipmentLabel}`}
                                    />
                                </Grid>
                            }

                            {data.actions?.includes("CUSTOMER_REQUEST") &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getOutletActionButton("request", t('outlet.type.CUSTOMER_REQUEST'))}
                                        icon={<WavingHandIcon {...getOutletActionButton()} sx={outletActionButtonIcon()} />}
                                        link={`/${getLanguageFromURL()}/request/${outletURL}?e=${equipmentLabel}`}
                                    />
                                </Grid>
                            }


                            {data.actions?.includes("WAITLIST") &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getOutletActionButton("request", t('outlet.type.WAITLIST'))}
                                        icon={ <SvgIcon component={Waitlist} sx={outletActionButtonIconSvg()} />}
                                        // iconSx={getOutletActionButtonSvg()}
                                        link={`/${getLanguageFromURL()}/waitlist/${outletURL}?e=${equipmentLabel}`}
                                    />
                                </Grid>
                            }

                            {data.actions?.includes("WIFI") &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getOutletActionButton("wifi", t('outlet.type.WIFI'))}
                                        icon={<Wifi {...getOutletActionButton()} sx={outletActionButtonIcon()} />}
                                        link={`/${getLanguageFromURL()}/wifi/${outletURL}?e=${equipmentLabel}`}
                                    />
                                </Grid>
                            }

                            {data.actions?.includes("RATING") &&
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getOutletActionButton("rating", t('outlet.type.RATING'))}
                                        icon={<Reviews {...getOutletActionButton()} sx={outletActionButtonIcon()} />}
                                        link={`/${getLanguageFromURL()}/rating/${outletURL}/add?e=${equipmentLabel}`}
                                    />
                                </Grid>
                            }

                            {data.extraOutletActionButtons?.length > 0 &&
                                data.extraOutletActionButtons?.map((actioBtn) =>
                                    <Grid item xs={6}>
                                        <CustomIconButton
                                            {...getOutletActionButton("custom", actioBtn.label[getLanguageFromURL()])}
                                            icon={<FontAwesomeIcon icon={fab[actioBtn.icon]} style={outletActionButtonFontAwesomeIcon()} />}
                                            link={actioBtn.url}
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            // onClick={() => navigate(`/${getLanguageFromURL()}/${outletURL}/webview`, { url: actioBtn.url })}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>

                    {dialog.isOpen &&
                        <CustomDialogChildren
                            isOpen={dialog.isOpen}
                            id="dialog"
                            title={t(`request.popup.${dialog.type}`)}
                            actionLabel={dialog.type === "info" ? t("request.btns.Send") : ""}
                            cancelLabel={t("request.btns.ok")}
                            message={dialog.message}
                            action={undefined}
                            handleOpen={handleDialogState}
                            actionColor="#e91e1e"
                            children={getDialogTypeAnimation(dialog.type)}
                        />
                    }

                    {outletDialog.isOpen &&
                        <CustomDialogBasedOnScreenChildren
                            isOpen={outletDialog.isOpen}
                            id="outlet-details"
                            handleOpen={() => handleOutletDialogState(true)}
                            handleClose={() => handleOutletDialogState(false, "", "")}
                            actionColor="#e91e1e"
                            children={
                                <CustomOutletDetailsCard
                                    handleReviewsClick={() => navigate({
                                        pathname: "feedback",
                                        search: createSearchParams({
                                            e: equipmentLabel
                                        }).toString()
                                    })}
                                    outlet={data.outlet}
                                    organizationID={data.organization.id}
                                    outletID={data.outlet.id}
                                    outletURL={outletURL}
                                    showLanguage={false}
                                />
                            }
                        />
                    }
                </CustomGrid>
            );
    }
}

export default OutletComponent;