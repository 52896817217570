import { request } from "constants/alias";
import { API } from "constants/config";

/**
 * The rest request which return required information to be presented on add outlet waitlist page.
 * 
 * @returns an object with an attribute containing the requested information to be presented on add outlet waitlist page
 */
export async function fetchOutletNew(outletURL) {
    return await request.get(`${API}waitlist/outlet/${outletURL}`);
}

/**
 * The rest request which attempts to create a new outlet waitlist.
 * 
 * @param {*} data A JSON object which contains new outlet waitlist infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function createOutlet(outletURL, data) {
    return await request.post(`${API}waitlist/outlet/${outletURL}`, data);
}

const waitlistApi = {
    fetchOutletNew,
    createOutlet
};

export default waitlistApi;
