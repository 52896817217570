import { requestButton } from "themes/defaultThemes"

// REQUESTS
export const getRequestButton = (selectedType, key, type, label) => {
    return {
        keyValue: `btn-${key}`,
        backgroundColor: "#dcf1f7",
        label: label,
        sx: requestButton(),
        variant: 'outlined',
        isFocused: selectedType === type
    }
}
export const getRequestButtonIcon = () => {
    return {
        width: 100,
        height: 100,
        fill: "#54b1d2"
    }
}

// OUTLET
export const getOutletActionButton = (key, label) => {
    return {
        keyValue: `btn-${key}`,
        backgroundColor: "#dcf1f7",
        label: label,
        sx: requestButton(),
        variant: 'outlined'
    }
}
export const getOutletActionButtonIcon = () => {
    return {
        width: 100,
        height: 100,
        fill: "#54b1d2"
    }
}

export const getOutletActionButtonSvg = (key, label) => {
    return {
        keyValue: `btn-${key}`,
        backgroundColor: "#dcf1f7",
        label: label,
        sx: requestButtonSvg(),
        variant: 'outlined'
    }
}
export const requestButtonSvg = () => {
    return {
        width: "100%",
        color: "#54b1d2",
        fontWeight: "900",
        // fontSize: "18px",
        textTransform: "initial",
        padding: "20px 30px",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: "#54b1d2",
            border: "1px solid #54b1d2"
        }
    }
}

// =========== AUTH
export const PASSWORD_REQUIRED_LENGTH = 8;

// =========== ORDER
export const MORE_ORDER_ADD_SIZE = 10;

// =========== RATING
export const MORE_RATING_ADD_SIZE = 10;


// WAITLIST
export const getWaitlistPersons = () => {
    return {
        step: 1,
        min: 1,
        max: null,
        length: -1, //not defined
        isFloat: false
    }
}