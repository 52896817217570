import { getThemeColor } from "constants/theme"
import {
    lumanosimoTitleStyle
} from "themes/defaultThemes"
import { getTheme } from "utils/theme"

export const checkoutCommentTitleStyle = () => {
    return {
        fontSize: "18px",
        ...lumanosimoTitleStyle()
    }
}

export const checkoutCommentTextStyle = () => {
    return {
        fontWeight: 600,
        fontSize: "12px",
        fontFamily: "Lumanosimo"
    }
}

export const checkoutCommentTextareaBoxStyle = () => {
    return {
        display: 'flex',
        borderTop: '1px solid',
        borderColor: 'divider',
        flex: 'auto',
        justifyContent: "right"
    }
}

export const checkoutCommentTextareaBoxInfoCharStyle = () => {
    return {
        fontSize: "12px",
        alignSelf: "self-end"
    }
}

export const paymentMethodButton = () => {
    return {
        width: "100%",
        color: getThemeColor()[getTheme()]["addButtonBackgroundColor"],
        fontWeight: "600",
        fontSize: "13px",
        textTransform: "initial",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: getThemeColor()[getTheme()]["addButtonFontColor"],
        }
    }
}

export const getPaymentMethodButtonIcon = () => {
    return {
       width: 40,
       height: 40,
       fill: "#54b1d2"
    }
 }
export const paymentMethodButtonIcon = () => {
    return {
       marginRight: "10px",
       ":hover": {
          color: "white"
       },
    };
 }

//  =========== WAITLIST

export const waitlistCustomerNotesTextareaBoxInfoCharStyle = () => {
    return {
        fontSize: "12px",
        alignSelf: "self-end"
    }
}