import { Box, Divider, FormHelperText, Grid, InputLabel } from '@mui/material';
import waitlistApi from 'api/waitlist';
import { LottieFeedbackSuccess, LottieSuccessConfetti } from 'assets/lottie/LottieAnimations';
import { MessageIcon } from 'assets/mui/MuiIcons';
import CustomDelayedOrHiddenLottieAnimation from 'components/Asset/CustomDelayedOrHiddenLottieAnimation';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomDialogBasedOnScreenChildren from 'components/Dialog/CustomDialogBasedOnScreenChildren';
import { getDialogTypeAnimation } from 'components/Dialog/animation/dialogTypeAnimation';
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeaderChildren from 'components/Header/CustomTopHeaderChildren';
import RatingtLoadingSkeleton from 'components/Loading/RatingtLoadingSkeleton';
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTypography from 'components/Typography/CustomTypography';
import { lottieOrderRatingOptions, lottieOrderSuccessRatingOptions } from 'constants/animations/lottieOptions';
import { getDefaultWaitlistObject } from 'constants/defaultObjects';
import { getWaitlistPersons } from 'constants/fieldProperties';
import { requestTextFieldLimit } from 'constants/textFieldLimit';
import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateOutletSplashConfiguration } from 'store/splash/splashAction';
import { checkoutCommentTextareaBoxStyle, waitlistCustomerNotesTextareaBoxInfoCharStyle } from 'themes/checkout/checkoutTheme';
import { mainTag, orderIconStyle, ratingInfoText, ratingMessageTitleTextStyle, selectError, textFieldError } from 'themes/defaultThemes';
import { formValChangeWithParentElement, onSelectChangeWithParents } from 'utils/form-validation';
import { formValChangeWithParentElementWithNameAndValue, validateInputNumber } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The WaitlistComponent, that presents a new outlet's order rating component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function WaitlistComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { outletURL } = useParams();
    const params = new URLSearchParams(location.search);
    const ss = params.get("ss");

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        organization: {},
        outlet: {},
        waitlist: {},
        enabledWaitlistRequirementOptions: [],
        enabledWaitilistTagOptions: [],
        isAdded: false,
        showSplash: false,
        // error messages per field
        isError: {
            customerName: "",
            customerEmail: "",
            customerPhone: "",
        }
    });
    const [isLoaded, setIsLoaded] = useState(false);
    const [disableSendButton, setDisableSendButton] = useState(true);
    // login
    const [isOpenLogin, setIsOpenLogin] = useState(false);

    // splash
    const dispatch = useDispatch();
    const outletSplashConfiguration = useSelector(state => state.splash[outletURL] || null);
    const [splashCount, setSplashCount] = useState(0);
    const [showSplash, setShowSplash] = useState(true);


    useEffect(() => {
        getWaitlist();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && ss === "1") {
            const interval = setInterval(() => {
                setSplashCount((prevCount) => prevCount + 1);
            }, 1000); // Increment count every second

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ss]);

    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && splashCount >= outletSplashConfiguration?.splashConfiguration.durationInSeconds && showSplash) {
            setShowSplash(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [splashCount]);

    const [dialog, setDialog] = useState({
        isOpen: false,
        type: "success",
        message: ""
    });
    // create a counter to enable the resend code logic after 30sec
    const timerIdRef = useRef(0);
    const [counter, setCounter] = useState(120);

    /**
     * Callback that handles the start of the time interval for the resend code button to be shown on end.
     * @returns nothing
     */
    function startHandler() {
        if (timerIdRef.current) {
            return;
        }
        timerIdRef.current = setInterval(() => setCounter((prevCounter) => (prevCounter === 0) ? stopHandler() : prevCounter - 1), 1000);
    }

    /**
     * Callback that handles the end of the time interval for the resend code button to be shown.
     */
    function stopHandler() {
        clearInterval(timerIdRef.current);
        timerIdRef.current = 0;
        return 0;
    }


    //change document title
    useDocumentTitle(`${t('rating.outlet.pageTitle')}`);

    /**
     * The rest endpoint to get the outlet order rating details.
     */
    function getWaitlist(sendButtonISPressed = true) {
        waitlistApi.fetchOutletNew(outletURL).then((r) => {
            if (r.data.code === "SUCCESS" || r.data.code === "PERMISSION")
                Promise.resolve()
                    .then(() => {
                        if (sendButtonISPressed) {
                            if (r.data?.code === "PERMISSION") {
                                setIsOpenLogin(true);
                            } else {
                                setIsOpenLogin(false);
                                setDisableSendButton(true);
                            }
                        }
                    })
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            outlet: r.data.returnobject.outlet,
                            enabledWaitlistRequirementOptions: r.data.returnobject?.enabledWaitlistRequirementOptions,
                            enabledWaitilistTagOptions: r.data.returnobject?.enabledWaitilistTagOptions,
                            showSplash: r.data.returnobject.ss,
                            waitlist: getDefaultWaitlistObject()
                        });
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
                    .then(() => {
                        dispatch(updateOutletSplashConfiguration(
                            outletURL,
                            r.data.returnobject.outlet.settings?.splashScreenConfiguration,
                            r.data.returnobject.organization.id,
                            r.data.returnobject.outlet.id,
                            r.data.returnobject.outlet.logo,
                            "menu"
                        ));
                    })
        }).catch((e) => {
            // console.log(e);
        });
    }

    /**
     * The rest endpoint to add the outlet order customer rating.
     */
    function joinWaitlist() {
        if (disableSendButton) {
            setCounter(120);
            startHandler();
            handleDialogState(true, "info", t('waitlist.alreadySendRequestMessage'))
        } else {
            setDisableSendButton(true);
            if (data.rating?.message?.length > 100) {
            } else {
                waitlistApi.createOutlet(outletURL, data.rating).then((r) => {
                    if (r.data.code === "SUCCESS") {
                        formValChangeWithParentElementWithNameAndValue("isAdded", true, data, setData);
                    }
                }).catch((e) => {
                    // console.log(e);
                });
            }

        }
    }

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} type The type of the dialog 'success', 'fail' or 'info'.
     */
    function handleDialogState(isOpen, type = "success", message = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            type: type,
            message: message
        });
    }

    /**
     * Function that triggers the customerPhone value change.
     * @param {string} newPhone the update customerPhone value
     */
    function onPhoneChange(newPhone) {
        setData({
            ...data,
            user: {
                ...data.waitlist,
                customerPhone: newPhone
            }
        });
    }

    // ===============================================================================RENDER
    if (!isLoaded) {
        return <CustomGrid role="main" id="request" sx={mainTag()}>
            <CustomTopHeaderChildren
                key="th"
                isOpen={isOpenLogin}
                handleLoginCheckout={() => getWaitlist()}
                setIsOpen={setIsOpenLogin}
                pageRequireLoginRegistation={isOpenLogin}
            />
            <RatingtLoadingSkeleton />
        </CustomGrid>
    } else {
        const { isError } = data;

        return (
            <CustomGrid role="main" id="rating" sx={mainTag()}>
                <CustomTopHeaderChildren
                    key="th"
                    isOpen={isOpenLogin}
                    handleLoginCheckout={() => getWaitlist()}
                    setIsOpen={setIsOpenLogin}
                    pageRequireLoginRegistation={isOpenLogin}
                />

                <Grid container spacing={2} justifyContent={"center"} justifyItems={"center"} sx={{ width: "100%", margin: "0px 20px 20px" }}>
                    {data.isAdded &&
                        <>
                            <Grid item xs={12} md={12} sx={{ textAlign: "-webkit-center", mr: "10px" }}>
                                <CustomDelayedOrHiddenLottieAnimation
                                    delay={0}
                                    duration={4700}
                                    lottieTag={
                                        <Lottie
                                            {...lottieOrderRatingOptions}
                                            animationData={LottieSuccessConfetti}
                                        />
                                    }
                                />
                                <CustomDelayedOrHiddenLottieAnimation
                                    delay={4700}
                                    // duration={1000}
                                    lottieTag={
                                        <Lottie
                                            {...lottieOrderSuccessRatingOptions}
                                            animationData={LottieFeedbackSuccess}
                                        />
                                    }
                                />
                                <CustomTypography variant="body2" text={t('rating.outlet.ratingInfo')} sx={ratingInfoText()} />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ textAlign: "center", marginTop: "30px" }}>
                                <CustomIconButton
                                    variant="outlined"
                                    onClick={() => navigate(`/${getLanguageFromURL()}/${outletURL}`)}
                                    label={t('rating.outlet.back')}
                                    sx={{ textTransform: "none" }}
                                />
                            </Grid>
                        </>
                    }

                    {!data.isAdded &&
                        <>
                            <Grid item xs={2} md={1}>
                                <MessageIcon sx={orderIconStyle} />
                            </Grid>

                            <Grid item xs={10} md={3} sx={{ alignSelf: "center" }}>
                                <CustomTypography variant="body2" text={t('rating.outlet.message')} sx={ratingMessageTitleTextStyle()} />
                            </Grid>
                            {/* 
                            <Grid item xs={12} sm={6}>
                                <InputLabel required={true} htmlFor="customerEmail">{t('waitlist.customerEmail')}</InputLabel>
                                <CustomTextField
                                    type="customerEmail"
                                    name="waitlist.customerEmail"
                                    id="customerEmail"
                                    placeholder="name@company.com"
                                    required={true}
                                    value={data.waitlist.customerEmail}
                                    error={isError.customerEmail.length > 0 ? true : false}
                                    helperText={isError.customerEmail}
                                    sx={textFieldError()}
                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={6}>
                                <InputLabel required={true} htmlFor="customerPhone">{t('waitlist.customerPhone')}</InputLabel>
                                <PhoneInput
                                    value={data.waitlist.customerPhone}
                                    name="waitlist.customerPhone"
                                    id="customerPhone"
                                    placeholder="Add your customerPhone number" required={true}
                                    className={isError.customerPhone.length > 0 ? "is-invalid" : ""}
                                    onChange={onPhoneChange}
                                    sx={{ width: "100%", }}
                                    country="gr"
                                    inputStyle={{ paddingLeft: "70px", width: "100%" }}
                                />
                                {isError.customerPhone.length > 0 &&
                                    <FormHelperText sx={selectError()}>
                                        {isError.customerPhone}
                                    </FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel required={true} htmlFor="persons">{t('waitlist.persons')}</InputLabel>
                                <CustomTextField
                                    value={data.waitlist.persons}
                                    type="number"
                                    variant="standard"
                                    step={getWaitlistPersons().step}
                                    min={getWaitlistPersons().min}
                                    onChange={(event) => {
                                        let value = validateInputNumber(event.target.value, -1, getWaitlistPersons().min, null, getWaitlistPersons().isFloat);
                                        if (value === undefined) value = data.waitlist.persons;
                                        formValChangeWithParentElementWithNameAndValue("waitlist.persons", value, data, setData)
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}></Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel htmlFor="waitlist.extraRequirements">{t('waitlist.extraRequirements.label')}</InputLabel>
                                <CustomSelectMultilingual
                                    id="extraRequirements"
                                    isMultiple={true}
                                    name="waitlist.extraRequirements"
                                    translationPath="waitlist.extraRequirements"
                                    required={true}
                                    defaultValue={data.waitlist.extraRequirements === null ? [] : data.waitlist.extraRequirements}
                                    options={data.enabledWaitlistRequirementOptions}
                                    onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel htmlFor="extraTags">{t('waitlist.extraTags.label')}</InputLabel>
                                <CustomSelectMultilingual
                                    id="extraTags"
                                    isMultiple={true}
                                    name="waitlist.extraTags"
                                    translationPath="waitlist.extraTags"
                                    required={true}
                                    defaultValue={data.waitlist.extraTags === null ? [] : data.waitlist.extraTags}
                                    options={data.enabledWaitilistTagOptions}
                                    onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                <InputLabel htmlFor="customerNotes">{t('waitlist.customerNotes')}</InputLabel>
                                <CustomTextArea
                                    placeholder={t('checkout.requestPlaceholder')}
                                    value={data.waitlist.customerNotes}
                                    inputProps={{ maxLength: 100 }}
                                    maxLength={100}
                                    onChange={(event) => {
                                        let newValue = event.target.value.slice(0, 100);
                                        formValChangeWithParentElementWithNameAndValue("waitlist.customerNotes", newValue, data, setData);
                                    }}
                                    variant="outlined"
                                    sx={{ borderColor: "lightgrey", paddingTop: "15px" }}
                                    endDecorator={
                                        <Box sx={checkoutCommentTextareaBoxStyle}>
                                            <Divider flexItem={true} />
                                            <span style={{ color: data.waitlist?.customerNotes?.length === 100 ? "red" : "initial" }}>
                                                {`${data.waitlist?.customerNotes?.length}  `}
                                            </span>
                                            <span style={{ ...waitlistCustomerNotesTextareaBoxInfoCharStyle(), color: data.waitlist?.customerNotes?.length === 100 ? "red" : "initial" }}>
                                                {data.waitlist?.customerNotes?.length === 1 ? t('checkout.requestCharLimit') : t('checkout.requestCharsLimit')}
                                            </span>
                                            <span style={{ color: data.waitlist?.customerNotes?.length === 100 ? "red" : "initial" }}>
                                                {` /${requestTextFieldLimit}`}
                                            </span>
                                        </Box>
                                    }
                                />
                            </Grid>

                            <CustomIconButton
                                variant="outlined"
                                onClick={joinWaitlist}
                                label={t('waitlist.btn')}
                                disabled={disableSendButton}
                                sx={{ textTransform: "none", fontSize: "15px", marginTop: '20px' }}
                            />
                        </>
                    }

                    {dialog.isOpen &&
                        <CustomDialogBasedOnScreenChildren
                            isOpen={dialog.isOpen}
                            id="dialog"
                            // title={t(`request.popup.${dialog.type}`)}
                            actionLabel={dialog.type === "info" ? t("request.btns.Send") : ""}
                            cancelLabel={t("request.btns.ok")}
                            message={dialog.message}
                            action={dialog.type === "info" ? joinWaitlist : undefined}
                            handleOpen={() => handleDialogState(true)}
                            handleClose={() => handleDialogState(false, "", "")}
                            actionColor="#e91e1e"
                            counter={counter}
                            children={getDialogTypeAnimation(dialog.type)}
                        />
                    }
                </Grid>
            </CustomGrid>
        );
    }
}

export default WaitlistComponent;