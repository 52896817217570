import { getLangOptions } from "constants/langOptions";
import { AuthConsumer } from "context/AuthContext";
import CheckoutComponent from "pages/checkout/checkout.component";
import CouponComponent from "pages/coupon/coupon.component";
import ProfileComponent from "pages/customer/profile.component";
import SettingsComponent from "pages/customer/settings.component";
import SuccessAccountDeleteComponent from "pages/customer/success-accout-delete.component";
import AddOrderRatingComponent from "pages/feedback/add-order-rating.component";
import AddOutletRatingCOmponent from "pages/feedback/add-outlet-rating.component";
import FeedbackListComponent from "pages/feedback/feedback-list.component";
import LoyaltyComponent from "pages/loyalty/loyalty.component";
import MenuViewComponent from "pages/menu/menu-view.component";
import FailOrderComponent from "pages/order/fail-order.component";
import OrderComponent from "pages/order/order.component";
import OrdersComponent from "pages/order/orders.component";
import SuccessOrderComponent from "pages/order/success-order.component";
import UnpaidOrdersComponent from "pages/order/unpaid-orders.component";
import OutletComponent from "pages/outlet/outlet.component";
import QrReaderComponent from "pages/qr-reader.component";
import RequestComponent from "pages/request/request.component";
import WaitlistComponent from "pages/waitlist/waitlist.component";
import WifiComponent from "pages/wifi/wifi-component";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

/**
 * The Auth Routes.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AuthRoutes() {
  const location = useLocation();
  let mylang = localStorage.getItem("i18nextLng").substring(0, 2);
  if (mylang === null || mylang === undefined) {
    mylang = "en";
    localStorage.setItem("i18nextLng", "en");
  }
  let theme = localStorage.getItem("theme");
  if (theme !== undefined && theme !== "light" && theme !== "dark" && theme !== "") {
    localStorage.setItem("theme", "light");
  }

  // Check if the path starts with /en/ or /gr/
  const path = location.pathname;
  if (!path.startsWith("/en") && !path.startsWith("/el")) {
    // If the path doesn't start with /en/ or /gr/, redirect to the same path with /en/ in front
    // localStorage.setItem("i18nextLng", "en");
    return <Navigate to={`/${mylang}${path}${location.search}`} replace />;
  }

  return (
    <React.Fragment>
      <AuthConsumer>
        {({ user, handleLogout }) => (
          <Routes>
            {getLangOptions().map((lang, i) => (
              <>
                <Route key="ratings" caseSensitive path={`/${lang}/mycoupons`} element={<CouponComponent />} />
                <Route key="ratings" caseSensitive path={`/${lang}/myratings`} element={<FeedbackListComponent />} />
                <Route key="outlet-feedbacks" caseSensitive path={`/${lang}/menu/:outletURL/feedbacks`} element={<FeedbackListComponent />} />
                <Route key="order-feedback" caseSensitive path={`/${lang}/myorders/:orderID/feedback`} element={<AddOrderRatingComponent />} />
                <Route key="order-feedback-add" caseSensitive path={`/${lang}/myorders/:orderID/feedback/add`} element={<AddOrderRatingComponent />} />
                <Route key="outlet-rating-add" caseSensitive path={`/${lang}/rating/:outletURL/add`} element={<AddOutletRatingCOmponent />} />
                <Route key="success-order-feedback-add" caseSensitive path={`/${lang}/order/:orderID/feedback/add`} element={<AddOrderRatingComponent />} />
                <Route key="order" caseSensitive path={`/${lang}/myorders/:orderID`} element={<OrderComponent />} />
                <Route key="menu" caseSensitive path={`/${lang}/menu/:outletURL`} element={<MenuViewComponent />} />
                <Route key="checkout" caseSensitive path={`/${lang}/checkout/:outletURL`} element={<CheckoutComponent />} />
                <Route key="checkout-fail" caseSensitive path={`/${lang}/checkout/:outletURL/fail`} element={<FailOrderComponent />} />
                <Route key="checkout-success" caseSensitive path={`/${lang}/checkout/:outletURL/success`} element={<SuccessOrderComponent />} />
                <Route key="pay" caseSensitive path={`/${lang}/pay/:outletURL`} element={<UnpaidOrdersComponent />} />
                <Route key="pay-order" caseSensitive path={`/${lang}/pay/order/:orderID`} element={<OrderComponent />} />
                <Route key="request" caseSensitive path={`/${lang}/request/:outletURL`} element={<RequestComponent />} />
                <Route key="orders" caseSensitive path={`/${lang}/myorders`} element={<OrdersComponent />} />
                <Route key="loyalty" caseSensitive path={`/${lang}/loyalty`} element={<LoyaltyComponent />} />
                <Route key="coupon" caseSensitive path={`/${lang}/coupon`} element={<CouponComponent />} />
                <Route key="profile" caseSensitive path={`/${lang}/profile`} element={<ProfileComponent />} />
                <Route key="language" caseSensitive path={`/${lang}/settings`} element={<SettingsComponent />} />
                <Route key="wifi" caseSensitive path={`/${lang}/wifi/:outletURL`} element={<WifiComponent />} />
                <Route key="waitlist" caseSensitive path={`/${lang}/waitlist/:outletURL`} element={<WaitlistComponent />} />
                <Route key="account-delete-success" caseSensitive path={`/${lang}/success/account/delete`} element={<SuccessAccountDeleteComponent />} />
                <Route key="outlet" caseSensitive path={`/${lang}/:outletURL`} element={<OutletComponent />} />
                {/*  */}
                <Route key="viva-wallet-success-payment" caseSensitive path={`/${lang}/vivaWalletSuccessPaymentPage`} element={<SuccessOrderComponent />} />

                {/* Redirect to a specific page (e.g., NotFoundPage) when no other routes match */}
                <Route path="*" element={<QrReaderComponent />} />
              </>
            ))}
          </Routes>
        )}
      </AuthConsumer>
    </React.Fragment>
  );
}

export default AuthRoutes;
